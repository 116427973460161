import axios from 'axios'

const state = () => ({
  pages: []
})

const getters = {
    pages(state) {
    return state.pages
  }
}

const mutations = {
  SET_PAGES(state, payload){
    state.pages = payload 
  }
}

const actions = {
  getPageBySlug({commit}, payload){
    axios.get(`https://lib.uzgeouniver.uz:82/api/the_only/the_page/${payload}/`)
    .then((res)=>{
      commit("SET_PAGES", res.data)
    })
    .catch((error)=>{console.log(error)})
  }
}

export default{
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

