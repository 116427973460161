<template>
  <div>
    <v-container fluid class="back__image">
      <v-row class="responsive__style mx-auto">
        <v-col col="12" class="position_rel">
          <div class="title__style">
            <p class="white--text text-h4 font-weight-bold mb-2 px-4">{{ $t('actions.xalqaro') }}</p>
            <v-breadcrumbs :items="elements" dark large class="pl-4">
              <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }" :disabled="item.disabled">
                <v-breadcrumbs-item>
                  <router-link style="color: white; text-decoration: none;" :to="item.href">
                    {{ $t(item.text) }}
                  </router-link>
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="secondary_bg">
      <v-container class="mx-auto px-sm-5 px-0">
        <v-row class="responsive__style">
          <v-col cols="12" class="pt-md-15 pt-10 mb-5 pl-4">
            <p class="text-h5 font-weight-bold text-start mb-1">{{ $t('actions.xalqaro1') }}
            </p>
            <p class="iconColor" style="width: 60px; padding: 1px;"></p>
          </v-col>
          <v-col cols="12" class="map__image px-lg-0 px-4">
            <img style="width: 100%;" src="@/assets/image/map.png" alt="">
            <!-- rus -->
            <div class="absoluteRus__map">
              <div style="width: 20px; height: 20px;background-color: #1e202f; border-radius: 50%; color: white; font-size: 12px; display: flex; align-items: center; justify-content: center;">
                <v-icon color="white" size="16">mdi-map-marker</v-icon>
              </div>
              <span class="hoverRus__map">
                <div class="text-center">
                  <div>
                    <img width="90" src="@/assets/image/russian.png" alt="">
                    <p style="font-size: 16px; font-weight: 600">Rossiya Federatsiyasi</p>
                    <ul style="font-size:14px; text-align:start">
                      <li>Tomsk Davlat milliy tadqiqot universiteti</li>
                      <li>Sergo Orjonikidze nomidagi Rossiya davlat geologiya-qidiruv universiteti</li>
                      <li>Novosibirsk davlat universiteti</li>
                      <li>Ural davlat konchilik universiteti</li>
                      <li>Qozon federal universiteti</li>
                      <li>M. V. Lomonosov nomidagi Moskva davlat universiteti</li>
                      <li>Sibir federal universiteti</li>
                      <li>Federal davlat ilmiy muassasasi “Rossiya fanlar akademiyasining Ural bo'limi KOMI ilmiy markazi" Federal tadqiqot markazi</li>
                      <li>“НАНОЭКОХИМ” MCHJ</li>
                    </ul>
                  </div>
                </div>
              </span>
            </div>
            <!-- uk -->
            <div class="absoluteUk__map">
              <div style="width: 20px; height: 20px;background-color: #1e202f; border-radius: 50%; color: white; font-size: 12px; display: flex; align-items: center; justify-content: center;">
                <v-icon color="white" size="16">mdi-map-marker</v-icon>
              </div>
              <span class="hoverUk__map">
                <div class="text-center">
                  <div>
                    <img width="90" src="@/assets/image/british.png" alt="">
                    <p style="font-size: 16px; font-weight: 600">Buyuk Britaniya, London</p>
                    <ul style="font-size:14px; text-align:start">
                      <li>Rossiya va Markaziy Yevrosiyo mineral tadqiqotlari markazi, tabiiy tarix muzeyi</li>
                    </ul>
                  </div>
                </div>
              </span>
            </div>
            <!-- ger -->
            <div class="absoluteGr__map">
              <div style="width: 20px; height: 20px;background-color: #1e202f; border-radius: 50%; color: white; font-size: 12px; display: flex; align-items: center; justify-content: center;">
                <v-icon color="white" size="16">mdi-map-marker</v-icon>
              </div>
              <span class="hoverGr__map">
                <div class="text-center">
                  <div>
                    <img width="90" src="@/assets/image/germany.png" alt="">
                    <p style="font-size: 16px; font-weight: 600">Germaniya</p>
                    <ul style="font-size:14px; text-align:start">
                      <li>Frayberg konchilik akademiyasi texnik universiteti</li>
                    </ul>
                  </div>
                </div>
              </span>
            </div>
             <!-- belarus -->
             <div class="absoluteBl__map">
              <div style="width: 20px; height: 20px;background-color: #1e202f; border-radius: 50%; color: white; font-size: 12px; display: flex; align-items: center; justify-content: center;">
                <v-icon color="white" size="16">mdi-map-marker</v-icon>
              </div>
              <span class="hoverBl__map">
                <div class="text-center">
                  <div>
                    <img width="90" src="@/assets/image/flag.png" alt="">
                    <p style="font-size: 16px; font-weight: 600">Belarus Respublikasi</p>
                    <ul style="font-size:14px; text-align:start">
                      <li>Belorussiya milliy texnik universiteti</li>
                      <li>A. S. Pushkin nomidagi Brest davlat universiteti</li>
                    </ul>
                  </div>
                </div>
              </span>
            </div>
            <!-- spain -->
            <div class="absoluteSp__map">
              <div style="width: 20px; height: 20px;background-color: #1e202f; border-radius: 50%; color: white; font-size: 12px; display: flex; align-items: center; justify-content: center;">
                <v-icon color="white" size="16">mdi-map-marker</v-icon>
              </div>
              <span class="hoverSp__map">
                <div class="text-center">
                  <div>
                    <img width="90" src="@/assets/image/spain.png" alt="">
                    <p style="font-size: 16px; font-weight: 600">Ispaniya Qirolligi</p>
                    <ul style="font-size:14px; text-align:start">
                      <li>Ilmiy tadqiqotlar bo'yicha oliy kengash davlat agentligi, Ispaniya geologiya va mineral resurslar instituti orqali</li>
                    </ul>
                  </div>
                </div>
              </span>
            </div>
             <!-- italy -->
             <div class="absoluteIt__map">
              <div style="width: 20px; height: 20px;background-color: #1e202f; border-radius: 50%; color: white; font-size: 12px; display: flex; align-items: center; justify-content: center;">
                <v-icon color="white" size="16">mdi-map-marker</v-icon>
              </div>
              <span class="hoverIt__map">
                <div class="text-center">
                  <div>
                    <img width="90" src="@/assets/image/italy.png" alt="">
                    <p style="font-size: 16px; font-weight: 600">Italiya Respublikasi</p>
                    <ul style="font-size:14px; text-align:start">
                      <li>PISA universiteti</li>
                    </ul>
                  </div>
                </div>
              </span>
            </div>
             <!-- turkey -->
             <div class="absoluteTr__map">
              <div style="width: 20px; height: 20px;background-color: #1e202f; border-radius: 50%; color: white; font-size: 12px; display: flex; align-items: center; justify-content: center;">
                <v-icon color="white" size="16">mdi-map-marker</v-icon>
              </div>
              <span class="hoverTr__map">
                <div class="text-center">
                  <div>
                    <img width="90" src="@/assets/image/turkey.png" alt="">
                    <p style="font-size: 16px; font-weight: 600">Turkiya Respublikasi</p>
                    <ul style="font-size:14px; text-align:start">
                      <li>Turkiya Respublikasining CHUKUROV universiteti</li>
                      <li>Turkiya Respublikasining YEDITEPE universiteti</li>
                      <li>Turkiya Respublikasining Anqara universiteti</li>
                    </ul>
                  </div>
                </div>
              </span>
            </div>
             <!-- kz -->
             <div class="absoluteKz__map">
              <div style="width: 20px; height: 20px;background-color: #1e202f; border-radius: 50%; color: white; font-size: 12px; display: flex; align-items: center; justify-content: center;">
                <v-icon color="white" size="16">mdi-map-marker</v-icon>
              </div>
              <span class="hoverKz__map">
                <div class="text-center">
                  <div>
                    <img width="90" src="@/assets/image/kazak.png" alt="">
                    <p style="font-size: 16px; font-weight: 600">Qozog'iston Respublikasi</p>
                    <ul style="font-size:14px; text-align:start">
                      <li>L. N. Gumilyov nomidagi Yevrosiyo milliy universiteti</li>
                      <li>Evroosiyo texnologiya universiteti</li>
                    </ul>
                  </div>
                </div>
              </span>
            </div>
             <!-- ky -->
             <div class="absoluteKy__map">
              <div style="width: 20px; height: 20px;background-color: #1e202f; border-radius: 50%; color: white; font-size: 12px; display: flex; align-items: center; justify-content: center;">
                <v-icon color="white" size="16">mdi-map-marker</v-icon>
              </div>
              <span class="hoverKy__map">
                <div class="text-center">
                  <div>
                    <img width="90" src="@/assets/image/kirgiz.png" alt="">
                    <p style="font-size: 16px; font-weight: 600">Qirg'iziston Respublikasi</p>
                    <ul style="font-size:14px; text-align:start">
                      <li>I. Razzakov nomidagi Qirg‘iziston davlat texnika universiteti filiali</li>
                      <li>M.M. Adishev nomidagi O‘sh texnologik universiteti</li>
                    </ul>
                  </div>
                </div>
              </span>
            </div>
             <!-- china -->
             <div class="absoluteCh__map">
              <div style="width: 20px; height: 20px;background-color: #1e202f; border-radius: 50%; color: white; font-size: 12px; display: flex; align-items: center; justify-content: center;">
                <v-icon color="white" size="16">mdi-map-marker</v-icon>
              </div>
              <span class="hoverCh__map">
                <div class="text-center">
                  <div>
                    <img width="90" src="@/assets/image/china.png" alt="">
                    <p style="font-size: 16px; font-weight: 600">Xitoy Xalq Respublikasi</p>
                    <ul style="font-size:14px; text-align:start">
                      <li>Xitoy konchilik va texnologiya universiteti (Pekin)</li>
                      <li>Xitoy geofanlar universiteti (Vuhan)</li>
                      <li>"BGRIMM Technology Group" MCHJ</li>
                      <li>SEBINE Technology Inc</li>
                    </ul>
                  </div>
                </div>
              </span>
            </div>
             <!-- korea -->
             <div class="absoluteKo__map">
              <div style="width: 20px; height: 20px;background-color: #1e202f; border-radius: 50%; color: white; font-size: 12px; display: flex; align-items: center; justify-content: center;">
                <v-icon color="white" size="16">mdi-map-marker</v-icon>
              </div>
              <span class="hoverKo__map">
                <div class="text-center">
                  <div>
                    <img width="90" src="@/assets/image/korea.png" alt="">
                    <p style="font-size: 16px; font-weight: 600">Koreya Respublikasi</p>
                    <ul style="font-size:14px; text-align:start">
                      <li>KANGWON milliy universiteti</li>
                      <li>KYUNGWOON milliy universiteti</li>
                    </ul>
                  </div>
                </div>
              </span>
            </div>
             <!-- japan -->
             <div class="absoluteJp__map">
              <div style="width: 20px; height: 20px;background-color: #1e202f; border-radius: 50%; color: white; font-size: 12px; display: flex; align-items: center; justify-content: center;">
                <v-icon color="white" size="16">mdi-map-marker</v-icon>
              </div>
              <span class="hoverJp__map">
                <div class="text-center">
                  <div>
                    <img width="90" src="@/assets/image/japan.png" alt="">
                    <p style="font-size: 16px; font-weight: 600">Yaponiya</p>
                    <ul style="font-size:14px; text-align:start">
                      <li>Akita universiteti</li>
                    </ul>
                  </div>
                </div>
              </span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <UsefulLink />
  </div>
</template>

<script>

import Sidebar from "@/components/sidebar.vue";
import UsefulLink from "@/components/usefulLink.vue";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
export default {
  name: "About",
  components: {
    UsefulLink,
    Sidebar
  },
  data() {
    return {
      elements: [
        {
          text: 'actions.home',
          disabled: false,
          href: 'home',
        },
        {
          text: 'actions.xalqaro1',
          disabled: true,
          href: '/xalqaro_faoliyat',
        }
      ],

    
      region: "",
      foundRegion: { id: "UZ", name: "Uzbekistan" },

    }
  },
  watch: {
    region() {
      this.foundRegion = this.maps.find((map) => map.id == this.region)
    },
  },
  methods: {
    mouseOver: function (event) {
      console.log(event)
      this.region = event.target.id;
      const divId = document.getElementById("absolute-div");
      divId.style.left = (event.offsetX + 15) + "px";
      divId.style.top = (event.offsetY + 5) + "px";
      divId.style.visibility = "visible";
      // event.target.style.color = "black";
    },
    mouseLeave() {
      const divId = document.getElementById("absolute-div");
      divId.style.visibility = "hidden";
    },


  }
}
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "georgia";
}
.map__image{
  position: relative;
}

.absoluteRus__map{
  position: absolute;
  top:37.2%;
  left: 60.7%;
  transition: all 0.3s ease;
}
.hoverRus__map{
  width: 280px;
  padding: 25px 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .3);
  position: absolute;
  right: 90%;
  top:90%;
  background-color: white;
  border-radius: 5px;
  display: none;
  z-index: 99;
}
.absoluteRus__map:hover .hoverRus__map{
  display:block
}

.absoluteUk__map{
  position: absolute;
  top:42.5%;
  left: 48.5%;
  transition: all 0.3s ease;
}
.hoverUk__map{
  width: 280px;
  padding: 25px 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .3);
  position: absolute;
  right: 90%;
  top:90%;
  background-color: white;
  border-radius: 5px;
  display: none;
  z-index: 99;
}
.absoluteUk__map:hover .hoverUk__map{
  display:block
}

.absoluteGr__map{
  position: absolute;
  top:42.9%;
  left: 51.6%;
  transition: all 0.3s ease;
}
.hoverGr__map{
  width: 280px;
  padding: 25px 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .3);
  position: absolute;
  right: 90%;
  top:90%;
  background-color: white;
  border-radius: 5px;
  display: none;
  z-index: 99;
}
.absoluteGr__map:hover .hoverGr__map{
  display:block
}

.absoluteBl__map{
  position: absolute;
  top:41.9%;
  left: 56.6%;
  transition: all 0.3s ease;
}
.hoverBl__map{
  width: 280px;
  padding: 25px 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .3);
  position: absolute;
  right: 90%;
  top:90%;
  background-color: white;
  border-radius: 5px;
  display: none;
  z-index: 99;
}
.absoluteBl__map:hover .hoverBl__map{
  display:block
}

.absoluteSp__map{
  position: absolute;
  top:50%;
  left: 48%;
  transition: all 0.3s ease;
}
.hoverSp__map{
  width: 280px;
  padding: 25px 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .3);
  position: absolute;
  right: 90%;
  top:90%;
  background-color: white;
  border-radius: 5px;
  display: none;
  z-index: 99;
}
.absoluteSp__map:hover .hoverSp__map{
  display:block
}

.absoluteIt__map{
  position: absolute;
  top:48.8%;
  left: 52.3%;
  transition: all 0.3s ease;
}
.hoverIt__map{
  width: 280px;
  padding: 25px 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .3);
  position: absolute;
  right: 90%;
  top:90%;
  background-color: white;
  border-radius: 5px;
  display: none;
  z-index: 99;
}
.absoluteIt__map:hover .hoverIt__map{
  display:block
}

.absoluteTr__map{
  position: absolute;
  top:50.6%;
  left: 58.8%;
  transition: all 0.3s ease;
}
.hoverTr__map{
  width: 280px;
  padding: 25px 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .3);
  position: absolute;
  right: 90%;
  top:90%;
  background-color: white;
  border-radius: 5px;
  display: none;
  z-index: 99;
}
.absoluteTr__map:hover .hoverTr__map{
  display:block
}

.absoluteKz__map{
  position: absolute;
  top:47.4%;
  left: 70.6%;
  transition: all 0.3s ease;
}
.hoverKz__map{
  width: 280px;
  padding: 25px 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .3);
  position: absolute;
  right: 90%;
  top:90%;
  background-color: white;
  border-radius: 5px;
  display: none;
  z-index: 99;
}
.absoluteKz__map:hover .hoverKz__map{
  display:block
}

.absoluteKy__map{
  position: absolute;
  top:49.6%;
  left: 69.8%;
  transition: all 0.3s ease;
}
.hoverKy__map{
  width: 280px;
  padding: 25px 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .3);
  position: absolute;
  right: 90%;
  top:90%;
  background-color: white;
  border-radius: 5px;
  display: none;
  z-index: 99;
}
.absoluteKy__map:hover .hoverKy__map{
  display:block
}

.absoluteCh__map{
  position: absolute;
  top:52.3%;
  left: 79.7%;
  transition: all 0.3s ease;
}
.hoverCh__map{
  width: 280px;
  padding: 25px 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .3);
  position: absolute;
  right: 90%;
  top:90%;
  background-color: white;
  border-radius: 5px;
  display: none;
  z-index: 99;
}
.absoluteCh__map:hover .hoverCh__map{
  display:block
}

.absoluteKo__map{
  position: absolute;
  top:51.8%;
  left: 84.5%;
  transition: all 0.3s ease;
}
.hoverKo__map{
  width: 280px;
  padding: 25px 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .3);
  position: absolute;
  right: 90%;
  top:90%;
  background-color: white;
  border-radius: 5px;
  display: none;
  z-index: 99;
}
.absoluteKo__map:hover .hoverKo__map{
  display:block
}

.absoluteJp__map{
  position: absolute;
  top:51.8%;
  left: 87.8%;
  transition: all 0.3s ease;
}
.hoverJp__map{
  width: 280px;
  padding: 25px 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .3);
  position: absolute;
  right: 90%;
  top:90%;
  background-color: white;
  border-radius: 5px;
  display: none;
  z-index: 99;
}
.absoluteJp__map:hover .hoverJp__map{
  display:block
}


#chartdiv {
  width: 100%;
  height: 700px;
}

.back__image {
  width: 100%;
  height: 450px;
  background-image: linear-gradient(to left, rgba(245, 246, 252, 0.01), #1e202f83, #1e202fcf, var(--main-color)), url('../assets/image/gfu9.jpg');
  background-position: center;
  background-size: cover;
  padding: 20px;
}

.position_rel {
  width: 100%;
  height: 60vh;
  position: relative;
  margin: 0 auto;
}

.title__style {
  position: absolute;
  left: 0%;
  top: 45%;
  transform: translate(0%, -45%);
}

.univer__image-style {
  width: 100%;
  max-width: 500px;
  height: 320px;
  border-radius: 10px;
}

.univer__image-style1 {
  width: 100%;
  max-width: 500px;
  height: 250px;
  border-radius: 8px;
}

.body__text-style {
  font-size: 18px;
  text-align: justify;
  font-family: "Roboto", sans-serif !important;
}

.svgSize {
  width: 100%;
  height: 480px
}

.section-hover {
  border-radius: 6px;
  box-shadow: 0 0 5px #343a40b7;
}

svg {
  width: 100%;
}

svg>path:hover {
  fill: #101111c7;
}

.mouseover {
  width: 100%;
  max-width: 200px;
  position: relative;
  background-color: #343a40ec;
  padding: 10px 15px;
  color: white;
  border: 1px solid rgb(39, 36, 36);
  z-index: 10;
  visibility: hidden;
}

.mouseover:hover {
  visibility: visible;
}

@media screen and (max-width: 600px) {
  .svgSize {
    width: 100%;
    height: 250px
  }
}
</style>