<template>
  <div>
    <v-container fluid class="back__image">
      <v-row class="responsive__style mx-auto">
        <v-col col="12" class="position_rel">
          <div class="title__style">
            <p class="white--text text-h4 font-weight-bold mb-2 px-4">{{ $t('actions.rektorSmall') }}</p>
            <v-breadcrumbs :items="elements" dark large class="pl-4">
              <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }" :disabled="item.disabled">
                <v-breadcrumbs-item>
                  <router-link style="color: white; text-decoration: none;" :to="item.href">
                    {{ $t(item.text) }}
                  </router-link>
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="mx-auto px-sm-5 px-0">
      <v-row v-if="rektorat.length" class="responsive__style">
        <v-col cols="12" class="pt-md-15 pt-10 mb-5 px-4">
          <p class="text-h5 font-weight-bold text-start mb-1">{{ $t('actions.rektorSmall') }}</p>
          <p class="iconColor" style="width: 60px; background-color: var(--main-color); padding: 1px;"></p>
        </v-col>
        <v-col cols="12" v-for="(item, index1) in sortByStatus.sort((a, b) => a.level - b.level)" :key="index1"
          class="px-4 mb-15">
          <v-row class="d-md-flex align-start ">
            <v-col cols="12" md="5">
              <img class="circle__image" :src="domen_name + item.image" />
            </v-col>
            <v-col cols="12" md="7">
              <div>
                <p class="grey--text text-body-1 font-weight-bold mb-0">{{ $t('actions.geoName1') }}</p>
                <p class="iconColor--text text-h5">
                  <span>
                    {{
                      getName({
                        nameUz: item.postion_uz,
                        nameRu: item.postion_ru,
                        nameEn: item.postion_en,
                      })
                    }}</span>
                </p>
                <p class="d-flex align-center">
                  <lord-icon class="mr-3" src="https://cdn.lordicon.com/bhfjfgqz.json" trigger="hover"
                    colors="primary:#3db166" style="width:20px;height:20px">
                  </lord-icon>
                  {{
                    getName({
                      nameUz: item.full_name_uz,
                      nameRu: item.full_name_ru,
                      nameEn: item.full_name_en,
                    })
                  }}
                </p>
                <p class="d-flex align-center">
                  <lord-icon class="mr-3" src="https://cdn.lordicon.com/ssvybplt.json" trigger="hover"
                    colors="primary:#3db166" style="width:20px;height:20px">
                  </lord-icon>
                  {{ item.phone }}
                </p>
                <p class="d-flex align-center">
                  <lord-icon class="mr-3" src="https://cdn.lordicon.com/diihvcfp.json" trigger="hover"
                    colors="primary:#3db166" style="width:20px;height:20px">
                  </lord-icon>
                  {{ item.email }}
                </p>
                <p class="d-flex align-center">
                  <lord-icon class="mr-3" src="https://cdn.lordicon.com/gigfpovs.json" trigger="hover"
                    colors="primary:#3db166" style="width:20px;height:20px">
                  </lord-icon>
                  {{
                    getName({
                      nameUz: item.res_uz,
                      nameRu: item.res_ru,
                      nameEn: item.res_en,
                    })
                  }}
                </p>
                <v-btn rounded outlined color="#3db166" dark class="mt-8" @click="detailInfo(item.id)"
                  style="text-decoration: none; display: flex; align-items: center;">
                  <lord-icon class="mr-3" src="https://cdn.lordicon.com/zmkotitn.json" trigger="hover"
                    colors="primary:#3db166" style="width:20px;height:20px"></lord-icon>
                  <p class="mb-0 font-weight-bold pr-2" style="text-transform: capitalize; color:var(--main-color)">{{
                    $t('actions.readMore')}}</p>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" style="height: 50vh; display: flex; justify-content: center; align-items: center">
          <v-progress-circular :size="70" :width="7" color="#0f172a" indeterminate></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <UsefulLink />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UsefulLink from "@/components/usefulLink.vue";

export default {
  name: "Rektorat",
  components: {
    UsefulLink
  },
  data() {
    return {
      domen_name: 'https://lib.uzgeouniver.uz:82',
      elements: [
        {
          text: 'actions.home',
          disabled: false,
          href: 'home',
        },
        {
          text: 'actions.rektorSmall',
          disabled: true,
          href: 'rektorat',
        }
      ],
      items: [
        {
          name: "Biz haqimizda",
          children: [
            { name: "Universitet haqida ma'lumot" },
            { name: "Umumiy ma'lumot" },
            { name: "Meyoriy hujjatlar" },
            { name: "Savollar" },
          ]
        },
        {
          name: "Tuzilma",
          children: [
            { name: "Tashkiliy tuzilma" },
            { name: "Rektorat" },
            { name: "Markazlar va bo'limlar" },
            { name: "Fakultetlar" },
            { name: "Kafedralar" },
            { name: "Ilmiy tadqiqot institutlari" },
            { name: "Geoinnovatsion texnologiyalar markazi" },
          ]
        },
        {
          name: "Faoliyat",
          children: ["Faoliyat"]
        },
        {
          name: "Talabalar",
          children: ["Talabalar"]
        },
        {
          name: "Abituriyentlarga",
          children: ["Abituriyentlarga"]
        },
        {
          name: "Yangiliklar",
          children: ["Yangiliklar"]
        },
        {
          name: "Elektron Kutubxona",
        }
      ],
    }
  },
  created() {
    this.getRektorat();
  },

  computed: {
    ...mapGetters("rektorat", ["rektorat"]),
    sortByStatus(){
      return this.rektorat.filter((vm)=> vm.status)
    }
  },
  methods: {
    ...mapActions("rektorat", ["getRektorat"]),

    detailInfo(id) {
      const routeActivityInfo = this.$router.resolve({ name: 'RektorInfo', query: { id: id, } });
      window.open(routeActivityInfo.href, '_self');
    },
  },
}
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.pdf__style {
  width: 70px;
  height: 25px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  background-color: #dc3545;
  color: white;
  font-weight: bolder;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  letter-spacing: 1.5px;
}

.word__style {
  width: 70px;
  height: 25px;
  background-color: #2962ff;
  color: white;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  font-weight: bolder;
  padding: 1px 0;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  letter-spacing: 1.5px;
}

.doc__style {
  width: 70px;
  height: 25px;
  background-color: #4082ed;
  color: white;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  font-weight: bolder;
  padding: 1px 0;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  letter-spacing: 1.5px;
}

.back__image {
  width: 100%;
  height: 400px;
  background-image: linear-gradient(to left, rgba(245, 246, 252, 0.01), #1e202f83, #1e202fcf, var(--main-color)), url('../assets/image/gfu10.jpg');
  background-position: center;
  background-size: cover;
  padding: 0;
}

.circle__image {
  width: 330px;
  height: 330px;
  border-radius: 50%;
  margin: 0 20px 20px 20px;
  object-fit: cover;
  object-position: center;
  border: 1px solid #bbbec4;
  padding: 10px;
}

.position_rel {
  width: 100%;
  height: 60vh;
  position: relative;
  margin: 0 auto;
}

.title__style {
  position: absolute;
  left: 0%;
  top: 45%;
  transform: translate(0%, -45%);
}


@media screen and (max-width: 425px) {
  .circle__image {
    width: 330px;
    height: 330px;
    margin: 0 10px 20px 10px;
    padding: 7px;
  }
}
</style>
