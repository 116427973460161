<template>
  <div>
    <Placard />
    <v-container fluid class="pt-15 background">
      <v-container class="mx-auto">
        <v-row class="responsive__style">
          <v-col cols="12" class="pt-10 mb-5">
            <p class="text-h6 font-weight-bold text-center" style="text-transform: uppercase;">
              {{ $t('interactive.mainRate') }}
            </p>
            <p class="iconColor" style="width: 60px; background-color: var(--main-color); margin: 0 auto; padding: 1px;"></p>
          </v-col>
         
          <v-col cols="12" sm="6" md="3" v-for="(vm, index) in asosiyKorsatkich" :key="index" class="hover__effect pb-0 my-lg-0 my-3" data-aos="1500">
          <div class="text-center inside__style">
            <div class="mb-3">
              <div class="iconEffect">
                <img :src="domen_name+vm.image" />
              </div>
            </div>
            <p class="text-md-h6 text-body-1 font-weight-bold mb-1" style="color:var(--main-color)">
              {{ vm.count }}</p>
            <p class="grey--text text-body-2">
              {{ 
                getName({
                nameUz:vm?.name_uz,
                nameRu:vm?.name_ru,
                nameEn:vm?.name_en
                })
             }}
            </p>
          </div>
        </v-col>

          <!-- <v-col cols="12" sm="6" md="3" v-for="(item, index) in datas" :key="index" class="hover__effect pb-0 my-lg-0 my-3"
            :data-aos="item.aos">
            <div class="text-center inside__style">
              <div class="mb-3">
                <div class="iconEffect">
                  <v-icon large color="var(--main-color)">mdi-{{ item.icon }}</v-icon>
                </div>
              </div>
              <p class="text-md-h6 text-body-1 font-weight-bold mb-1" style="color:var(--main-color)">{{ item.count }}</p>
              <p class="grey--text text-body-2">{{ $t(item.text) }}</p>
            </div>
          </v-col> -->
        </v-row>
      </v-container>
    </v-container>
    <HomeNews />
    <Interactive />
    <HomeTalimDasturi />
    <Question />
    <HomeVideo />
    <UsefulLink />
  </div>
</template>

<script>
import Placard from '@/components/homeComponents/placard.vue';
import Interactive from '../components/homeComponents/Interactive.vue'
import { Pagination, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import HomeNews from '@/components/homeComponents/homeNews.vue';
import Question from '@/components/homeComponents/question.vue';
import HomeVideo from '@/components/homeComponents/HomeVideo.vue'
import HomeTalimDasturi from '@/components/homeComponents/HomeTalimDasturi.vue';
import UsefulLink from '@/components/usefulLink.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'HomeView',
  components: {
    Placard,
    Swiper,
    SwiperSlide,
    Interactive,
    HomeTalimDasturi,
    HomeNews,
    Question,
    HomeVideo,
    UsefulLink
},
  setup() {
    return {
      modules: [Pagination, Navigation]
    }
  },
  data() {
    return {
      domen_name: 'https://lib.uzgeouniver.uz:82',
      arrayEvents: null,
      datePicker: false,
      date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      datas: [
        { icon: 'account-school', aos: 'zoom-in', count: "1 110", text: "actions.students" },
        { icon: 'bank', aos: 'zoom-in', count: "2", text: "actions.numberOfFaculty" },
        { icon: 'school', aos: 'zoom-in', count: "130", text: "actions.professors" },
        { icon: 'wallet-bifold', aos: 'zoom-in', count: "55 000", text: "actions.infoResourses" },
        { icon: 'table-chair', aos: 'zoom-in', count: "20", text: "actions.auditory" },
        { icon: 'chair-rolling', aos: 'zoom-in', count: "7", text: "actions.addTalimDas" },
        { icon: 'sign-direction', aos: 'zoom-in', count: "15", text: "actions.talimYunalish" },
        { icon: 'human-male-board-poll', aos: 'zoom-in', count: "11", text: "actions.kafedraSoni" },
      ],
      swiperOption: {
        // direction: 'vertical',

        spaceBetween: 0,
        initialSlide: 0,
        autoplay: true,
        slidesPerView: 'auto',
        allowTouchMove: false,
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        speed: 1200,
        cubeEffect: {
          slideShadows: true,
          shadow: true,
          shadowOffset: 20,
          shadowScale: 0.94
        }
      },
    }
  },
  created(){
    this.getAsosiyKorsatkich();
  },
  computed:{
    ...mapGetters("asosiyKorsatkich",["asosiyKorsatkich"]),
  },
  methods:{
    ...mapActions("asosiyKorsatkich",["getAsosiyKorsatkich"]),
  },
  mounted() {
    this.arrayEvents = [...Array(6)].map(() => {
      const day = Math.floor(Math.random() * 30)
      const d = new Date()
      d.setDate(day)
      return d.toISOString().substr(0, 10)
    })
  },
};
</script>

<style lang="scss" scoped>
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.iconEffect{
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 50%;
  background-color: #1e202f12;
  border: 1px dashed var(--main-color);
}
::v-deep img {
  width: 40px;
  height: 40px;
  }

.text__styling {
  color: var(--main-color);
  line-height: 28px;
  font-weight: 400;
  font-family: 'Book Antiqua', sans-serif;

}

.imageFirst3D {
  animation: mymove 5s infinite;
}

@keyframes mymove {
  from {
    transform: scale(1.1);
  }

  to {
    transform: scale(1);
  }

}

.swiper-container {
  width: 100%;
  height: 100vh;
  margin-top: 48px;
}

.swiper-slide {
  text-align: center;
  position: relative;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  z-index: 99;
}

#card {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #03030357;
}

.slide__image-style {
  width: 100%;
  height: auto;
}

.title__info {
  width: 60%;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.8);
  position: absolute;
  right: 50px;
  bottom: 100px;
  z-index: 999;
  padding: 20px;

  p {
    font-size: 25px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  }
}

.large__buy-button {
  position: absolute;
  right: 50px;
  bottom: 60px;
  z-index: 999;
}

.small__buy-button {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 999;
}

.calendar__style {
  position: absolute;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000075;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  left: 0;
  bottom: 50px;
  z-index: 99999;

}

.datePicker {
  display: none;
  position: absolute;
  left: 60px;
  bottom: 0px;
}

.calendar__style:hover .datePicker {
  display: block;
}

::v-deep .v-picker__title {
  padding: 0px 0 0 10px;
}

::v-deep .swiper-button-next {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #00000096;
  font-weight: 100;
  color: white;
}

::v-deep .swiper-button-prev {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #00000096;
  font-weight: 100;
  color: white;
}

.hover__effect {
  position: relative;
  transition: all 0.3s ease;
}

.inside__style {
  padding: 20px 10px;
  margin-bottom: 15px;
  transition: all 0.3s ease;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .2);
}

.circle__style {
  position: absolute;
  bottom: -6px;
  left: 49%;
  width: 6px;
  height: 6px;
  padding: 6px;
  border-radius: 100%;
  margin: 0 auto;
  border: 1px solid white;
  background-color: rgb(222, 220, 220);
  transition: all 0.3s ease;

}

.hover__effect:hover .inside__style {
  transform: translateY(-10px);
}

@media screen and (max-width: 992px) {
  .title__info {
    width: 75%;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.8);
    position: absolute;
    right: 20px;
    bottom: 44px;
    z-index: 999;
    padding: 10px;

    p {
      font-size: 14px;
      font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    }
  }

}

@media screen and (max-width: 768px) {
  .swiper-container {
    width: 100%;
    height: 380px;
  }
  .slide__image-style {
    height: 380px;
  }
}
</style>
