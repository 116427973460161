<template>
  <div>
    <v-container fluid class="back__image">
      <v-row class="responsive__style mx-auto">
        <v-col col="12" class="position_rel">
          <div class="title__style">
            <p class="white--text text-h4 font-weight-bold mb-2 px-4">{{ $t('actions.teacherInfo') }}</p>
            <v-breadcrumbs :items="elements" dark large class="pl-4">
              <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }" :disabled="item.disabled">
                <v-breadcrumbs-item>
                  <router-link style="color: white; text-decoration: none;" :to="item.href">
                    {{ $t(item.text) }}
                  </router-link>
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="mx-auto px-sm-5 px-0">
      <v-row class="responsive__style align-start">
        <v-col cols="12" class="pt-md-15 pt-10 mb-5 px-4">
          <p class="text-h5 font-weight-bold text-start mb-1">{{ $t('actions.information') }}</p>
          <p class="iconColor" style="width: 60px; background-color: var(--main-color); padding: 1px;"></p>
        </v-col>
        <v-col cols="12" md="4" class="px-2 mb-5">
          <v-card>
            <div class="d-block">
              <div class="text-center pt-3">
                <img class="circle__image" width="90%" :src="domen_name + newTeacherPage[0].image" />
              </div>
              <div class="px-5 pt-3 pb-5">
                <v-card-title class="text-center pb-3 pt-1"
                  style="text-transform: uppercase; font-size: 18px; font-weight: bold;">
                  <div class="mx-auto">
                    {{
                      getName({
                        nameUz: newTeacherPage[0]?.full_name_uz ? ((newTeacherPage[0]?.full_name_uz.split(' ')).slice(0,
                          2)).join(' ') : '',
                        nameRu: newTeacherPage[0]?.full_name_ru ? ((newTeacherPage[0]?.full_name_ru.split(' ')).slice(0,
                          2)).join(' ') : '',
                        nameEn: newTeacherPage[0]?.full_name_en ? ((newTeacherPage[0]?.full_name_en.split(' ')).slice(0,
                          2)).join(' ') : '',
                    })
                    }}
                    <br>
                    {{
                      getName({
                        nameUz: newTeacherPage[0]?.full_name_uz ? ((newTeacherPage[0]?.full_name_uz.split('')).slice(2)).join('') : '',
                    nameRu: newTeacherPage[0]?.full_name_ru ? ((newTeacherPage[0]?.full_name_ru.split('')).slice(2)).join('') : '',
                    nameEn: newTeacherPage[0]?.full_name_en ? ((newTeacherPage[0]?.full_name_en.split('')).slice(2)).join('') : '',})
                    }}
                  </div>
                </v-card-title>
                <v-card-subtitle class="text-body-1 pt-3 font-weight-medium">
                  <div class="text-center">
                    {{
                      getName({
                        nameUz: newTeacherPage[0].postion_uz,
                        nameRu: newTeacherPage[0].postion_ru,
                        nameEn: newTeacherPage[0].postion_en,
                      })
                    }}
                  </div>
                </v-card-subtitle>
                <v-card-subtitle class="my-2 py-1 text-body-1"><v-icon color="var(--logo-color)">mdi-clock</v-icon>
                  {{
                    getName({
                      nameUz: newTeacherPage[0].res_uz,
                      nameRu: newTeacherPage[0].res_ru,
                      nameEn: newTeacherPage[0].res_en,
                    })
                  }}
                </v-card-subtitle>
                <v-card-subtitle class="my-2 py-1 text-body-1"><v-icon color="var(--logo-color)">mdi-phone</v-icon>
                  {{ newTeacherPage[0].phone }}
                </v-card-subtitle>
                <v-card-subtitle class="my-2 py-1 text-body-1"><v-icon color="var(--logo-color)">mdi-email</v-icon>
                  {{ newTeacherPage[0].email }}
                </v-card-subtitle>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="8" class="mb-5 px-2">
          <v-card class="px-4">
            <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
              <v-tab key='k1' href='#k1' v-if="!hide">{{ $t('actions.task') }}</v-tab>
              <v-tab key='k2' href='#k2'>{{ $t('actions.bio') }}</v-tab>
              <v-tab key='k3' href='#k3'>{{ $t('actions.ilmiy') }}</v-tab>

              <v-tabs-slider color="var(--logo-color)"></v-tabs-slider>
            </v-tabs>
            <v-tabs-items v-model="tab" class="py-5">
              <v-tab-item key='k1' value='k1'>
                <div v-if="vazifalari" v-html="vazifalari"></div>
                <div v-else style="text-align:center;">Ma'lumot topilmadi</div>
              </v-tab-item>
              <v-tab-item key='k2' value='k2'>
                <div v-if="biografiya" v-html="biografiya"></div>
                <div v-else style="text-align:center;">Ma'lumot topilmadi</div>
              </v-tab-item>
              <v-tab-item key='k3' value='k3'>
                <div v-if="ilmiy_faoliyati" v-html="ilmiy_faoliyati"></div>
                <div v-else style="text-align:center;">Ma'lumot topilmadi</div>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <UsefulLink />
  </div>
</template>

<script>
import MenuTree from '../components/menuTree.vue';
import UsefulLink from "@/components/usefulLink.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Video",
  components: {
    MenuTree,
    UsefulLink
  },
  data() {
    return {
      locales: localStorage.getItem("locale") || 'uz',
      domen_name: 'https://lib.uzgeouniver.uz:82',
      tab: 'k1',
      hide: false,
      dialog: [false, false],
      videoSource: '',
      videos: [
        { image: "video2.jpg", videoSrc: "https://www.youtube.com/embed/6bDlMw5VhXM" },
        { image: "video4.jpg", videoSrc: "https://www.youtube.com/embed/wyAb_Z_2nD0" },
        { image: "video1.jpg", videoSrc: "https://www.youtube.com/embed/GhvSvqs92K0" },
        { image: "video3.jpg", videoSrc: "https://www.youtube.com/embed/atx-Jn3_wVQ" },
        { image: "gfu8.jpg", videoSrc: "https://www.youtube.com/embed/G40YPPMDADM" },
      ],
      items: [
        {
          name: "Biz haqimizda",
          children: [
            { name: "Universitet haqida ma'lumot" },
            { name: "Umumiy ma'lumot" },
            { name: "Meyoriy hujjatlar" },
            { name: "Savollar" },
          ]
        },
        {
          name: "Tuzilma",
          children: [
            { name: "Tashkiliy tuzilma" },
            { name: "Rektorat" },
            { name: "Markazlar va bo'limlar" },
            { name: "Fakultetlar" },
            { name: "Kafedralar" },
            { name: "Ilmiy tadqiqot institutlari" },
            { name: "Geoinnovatsion texnologiyalar markazi" },
          ]
        },
        {
          name: "Faoliyat",
          children: ["Faoliyat"]
        },
        {
          name: "Talabalar",
          children: ["Talabalar"]
        },
        {
          name: "Abituriyentlarga",
          children: ["Abituriyentlarga"]
        },
        {
          name: "Yangiliklar",
          children: ["Yangiliklar"]
        }
        ,
        {
          name: "Elektron Kutubxona",
        }
      ],
      elements: [
        {
          text: 'actions.home',
          disabled: false,
          href: 'home',
        },
        {
          text: "actions.teacherInfo",
          disabled: true,
          href: '/teacher',
        }
      ],
    }
  },
  created() {
    this.getPageBySlug(this.$route.query.section)
    console.log(this.$route.query.id);
  },
  computed: {
    ...mapGetters("allPage", ["pages"]),
    newTeacherPage() {
      let vm = this.pages?.employees?.filter((vm) => { return vm.id == this.$route.query.id })
      return vm
    },
    vazifalari() {
      return this.getName({ nameRu: this.newTeacherPage[0]?.vazifalari_ru, nameEn: this.newTeacherPage[0]?.vazifalari_en, nameUz: this.newTeacherPage[0]?.vazifalari_uz })
    },
    biografiya() {
      return this.getName({ nameRu: this.newTeacherPage[0]?.biografiya_ru, nameEn: this.newTeacherPage[0]?.biografiya_en, nameUz: this.newTeacherPage[0]?.biografiya_uz })
    },
    ilmiy_faoliyati() {
      return this.getName({ nameRu: this.newTeacherPage[0]?.ilmiy_faoliyati_ru, nameEn: this.newTeacherPage[0]?.ilmiy_faoliyati_en, nameUz: this.newTeacherPage[0]?.ilmiy_faoliyati_uz })
    },
  },
  methods: {
    ...mapActions("allPage", ['getPageBySlug']),

    openVideo(value, index) {
      this.dialog[index] = true;
      this.videoSource = value;
    },

    closeVideo(index) {
      this.dialog[index] = false;
      this.videoSource = null;
    }
  },
  watch: {
    openVideo() {
      this.$forceUpdate();
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.circle__image {
  width: 330px;
  height: 330px;
  border-radius: 50%;
  margin: 0 10px 0 10px;
  object-fit: cover;
  object-position: center;
  border: 1px solid #bbbec4;
  padding: 10px;
}

.back__image {
  width: 100%;
  height: 450px;
  background-image: linear-gradient(to left, rgba(245, 246, 252, 0.01), #1e202f83, #1e202fcf, var(--main-color)), url('../assets/image/teacher2.jpg');
  background-position: top;
  background-size: cover;
  padding: 20px;
}

.position_rel {
  width: 100%;
  height: 60vh;
  position: relative;
  margin: 0 auto;
}

.title__style {
  position: absolute;
  left: 0%;
  top: 45%;
  transform: translate(0%, -45%);
}

.playPause {
  position: absolute;
  cursor: pointer;
}

.windowHover {
  width: 100%;
  max-width: 550px;
  overflow: hidden;
  transition: all 0.3s ease;
  border-radius: 5px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .3);
  cursor: pointer;
}

.hoverImage {
  width: 100%;
  height: 230px;
}

@media screen and (max-width: 599px) {
  .windowHover {
    width: 430px;
  }

  .hoverImage {
    max-width: 430px;
  }
}

@media screen and (max-width: 475px) {
  .windowHover {
    width: 330px;
  }

  .hoverImage {
    max-width: 330px;
  }
}

@media screen and (max-width: 375px) {
  .windowHover {
    width: 300px;
  }

  .hoverImage {
    max-width: 300px;
  }
}

.positionRelative {
  position: relative;
  overflow: hidden;
}

.positionAbsolute {
  position: relative;
  overflow: hidden;
}

.positionAbsolute {
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(12, 12, 12, 0.3)
}
</style>