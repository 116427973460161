const state = () => ({
  search: [],
});

const getters = {
  search(state) {
    return state.search;
  },
};

const mutations = {
  SET_SEARCH(state, payload) {
    console.log(payload);
    state.search = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};