<template>
  <div>
    <swiper class="swiper" :options="swiperOption">
      <div class="swiper-button-next d-md-flex justify-center align-center d-none" slot="button-next"></div>
      <swiper-slide class="slide">
        <img :src="domen_name+carousel[0]?.image" class="slide__image-style" position="top" />
        <div>
          <div id="card1" class="title__info">
            <p class="text-justify white--text mb-0">
              {{ 
                getName({
                nameUz: carousel[0]?.title_uz,
                nameRu: carousel[0]?.title_ru,
                nameEn: carousel[0]?.title_en,
                }) 
              }}
            </p>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="slide">
        <img :src="domen_name+carousel[1]?.image" class="slide__image-style" position="top" />
        <div>
          <div id="card2" class="title__info">
            <p class="text-justify white--text mb-0">
              {{ 
                getName({
                nameUz: carousel[1]?.title_uz,
                nameRu: carousel[1]?.title_ru,
                nameEn: carousel[1]?.title_en,
                }) 
              }}
            </p>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="slide">
        <img :src="domen_name+carousel[2]?.image" class="slide__image-style" position="top" />
        <div>
          <div id="card3" class="title__info">
            <p class="text-justify white--text mb-0">
              {{ 
                getName({
                nameUz: carousel[2]?.title_uz,
                nameRu: carousel[2]?.title_ru,
                nameEn: carousel[2]?.title_en,
                }) 
              }}
            </p>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="slide">
        <img :src="domen_name+carousel[3]?.image" class="slide__image-style" position="top" />
        <div>
          <div id="card4" class="title__info">
            <p class="text-justify white--text mb-0">
              {{ 
                getName({
                nameUz: carousel[3]?.title_uz,
                nameRu: carousel[3]?.title_ru,
                nameEn: carousel[3]?.title_en,
                }) 
              }}
            </p>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-button-prev d-md-flex justify-center align-center d-none" slot="button-prev">
      </div>
    </swiper>
  </div>
</template>
  
<script>
import { Pagination, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { mapGetters, mapActions } from "vuex";

export default {
  name: 'Placard',
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination, Navigation]
    }
  },
  data() {
    return {
      domen_name: 'https://lib.uzgeouniver.uz:82',
      swiperOption: {
        spaceBetween: 0,
        initialSlide: 0,
        autoplay: true,
        slidesPerView: 'auto',
        allowTouchMove: false,
        loop: false,
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        speed: 1200,
        on: {
          slideChangeTransitionStart() {
            document.getElementById("card1").style.cssText = 'opacity:0; transform: translateX(-100px)';
            // document.getElementById("image1").style.cssText = 'animation:none';
            document.getElementById("card2").style.cssText = 'opacity:0; transform: translateX(-100px)';
            document.getElementById("card3").style.cssText = 'opacity:0; transform: translateX(-100px)';
            document.getElementById("card4").style.cssText = 'opacity:0; transform: translateX(-100px)';

          },
          slideChangeTransitionEnd() {
            document.getElementById("card1").style.cssText = 'opacity:1; transition: 0.3s;';
            // document.getElementById("image1").className = 'scaleMove1';
            document.getElementById("card2").style.cssText = 'opacity:1; transition: 0.3s;';
            // document.getElementById("image2").className = 'scaleMove2';
            document.getElementById("card3").style.cssText = 'opacity:1; transition: 0.3s;';
            // document.getElementById("image3").className = 'scaleMove3';
            document.getElementById("card4").style.cssText = 'opacity:1; transition: 0.3s;';
            // document.getElementById("image4").className = 'scaleMove4';
          }
        }
      },
    }
  },
  computed:{
    ...mapGetters("carousel", ["carousel"])
  },
  created() {
    this.getCarousel();
  },
  methods:{
    ...mapActions("carousel",["getCarousel"]),
  }
};
</script>
  
<style lang="scss" scoped>
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}


@keyframes mymove {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.01);

  }
}

.swiper-container {
  width: 100%;
  height: 100vh;
}

.swiper-slide {
  text-align: center;
  position: relative;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  z-index: 99;
}

#card {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.slide__image-style {
  width: 100%;
  height: auto;
}

.title__info {
  width: 60%;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.8);
  position: absolute;
  right: 50px;
  bottom: 50px;
  z-index: 999;
  padding: 20px;

  p {
    font-size: 23px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    line-height: 1.3 ;
  }
}

::v-deep .v-picker__title {
  padding: 0px 0 0 10px;
}

::v-deep .swiper-button-next {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #00000096;
  font-weight: 100;
  color: white;
  transition: all 0.5s ease;
  opacity: 0 !important;
}

::v-deep .swiper-button-prev {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #00000096;
  font-weight: 100;
  color: white;
  transition: all 0.5s ease;
  opacity: 0 !important;
}

.swiper:hover ::v-deep .swiper-button-next {
  opacity: 1 !important;
  animation: arrowRight 0.5s forwards;
}

.swiper:hover ::v-deep .swiper-button-prev {
  opacity: 1 !important;
  animation: arrowLeft 0.5s forwards;
}

@keyframes arrowLeft {
  from {
    transform: translate(20px);
  }
  to {
    transform: translate(0px);
  }
}

@keyframes arrowRight {
  from {
    transform: translate(-20px);
  }
  to {
    transform: translate(0px);
  }
}


@media screen and (max-width: 1200px) {
  .swiper-container {
    width: 100%;
    height: auto;
  }

  .slide__image-style {
    height: auto;
  }
}

@media screen and (max-width: 1024px) {
  .swiper-container {
    width: 100%;
    height: auto;
  }

  .slide__image-style {
    height: auto;
  }
}

@media screen and (max-width: 992px) {
  .title__info {
    width: 90%;
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.8);
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 999;
    padding: 10px;

    p {
      font-size: 13px;
      font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    }
  }

  .swiper-container {
    width: 100%;
    height: auto;
  }

  .slide__image-style {
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .swiper-container {
    width: 100%;
    height: 420px;
  }

  .slide__image-style {
    height: 420px;
  }
}
</style>