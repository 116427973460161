import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/HomeView"
import About from "@/views/pages/About"
import Rektorat from "@/views/Rektorat"
import RektorInfo from "@/views/rektorDetail"
import newPage from "@/views/Kafedra"
import Structure from "@/views/Structure"
import MahalliyHamkorlik from "@/views/MahalliyHamkorlik"
import XalqaroHamkorlik from "@/views/XalqaroHamkorlik"
import TeacherInfo from "@/views/TeacherInfo.vue";
import OnlineCamera from "@/views/OnlineCamera.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
    name: "Home",
    component: () => {
      return import("@/layouts/DefaultPage");
    },
    children: [
      {
        path: "",
        redirect: "/home",
      },
      {
        path: "home",
        name: "Home",
        component: Home,
      },
      {
        path: "/about",
        name: "About",
        component: About,
      },
      {
        path: "/rektorat",
        name: "Rektorat",
        component: Rektorat,
      },
      {
        path: "/rektoratInfo",
        name: "RektorInfo",
        component:RektorInfo,
      },
      {
        path: "/structure",
        name: "Structure",
        component: Structure,
      },
      {
        path: "/live",
        name: "OnlineCamera",
        component: OnlineCamera,
      },
      {
        path: "/mahalliy_hamkorlik",
        name: "MahalliyHamkorlik",
        component: MahalliyHamkorlik,
      },
      {
        path: "/xalqaro_faoliyat",
        name: "XalqaroHamkorlik",
        component: XalqaroHamkorlik,
      },
      {
        path: "/news",
        name: "News",
        component: () => {return import('../views/News') },
      },
      {
        path: "/elonlar",
        name: "News",
        component: () => {return import('../views/News') },
      },
      {
        path: "study-program-detail",
        name: "StudyProgramDetail",
        component: () => {return import('../views/StudyProgramDetail') },
      },
      {
        name: "NewsDetail",
        path: "news-detail",
        component: () => {return import('../views/newsDetail') },
      },
      {
        path: "/search",
        name: "Search",
        component: () => {return import('../components/searchResult') },
      },
      {
        path: "/fotogalereya",
        name: "Fotos",
        component: () => {return import('../views/Fotos') },
      },
      {
        path: "/videogallereya",
        name: "Videos",
        component: () => {return import('../views/Video') },
      },
       {
        path: "/live",
        name: "Live",
        component: () => {return import('../views/Live') },
      },
      {
        path: "/savollar",
        name: "Savollar",
        component: () => {return import('../views/Faq') },
      },
      {
        path: "/teacher",
        name: "TeacherInfo",
        component: TeacherInfo,
      },
      {
        path: ":name",
        name: "Kafedra",
        component: newPage,
      }
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => {
      return import("@/layouts/LoginPage");
    },
    children: [
      {
        path: "",
        name: "Login",
        component: () => {
          return import("../views/Login");
        },
      },
    ],
  },
  {
    path: "/employee",
    name: "Employee",
    component: () => {
      return import("@/layouts/TeacherPage");
    },
    // meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "Portfolio",
        component: () => {
          return import("../views/Portfolio");
        },
        // meta: { requiresAuth: true },
      },

    ],
  },
  {
    path: "**",
    name: "Error",
    component: () => { return import('../components/error') }
  }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
