<template>
  <div>
    <v-container fluid class="back__image">
      <v-row class="responsive__style mx-auto">
        <v-col col="12" class="position_rel">
          <div class="title__style">
            <p class="white--text text-h4 font-weight-bold mb-2 px-4" v-if="bodyTitle">
              {{
                getName({
                  nameUz:bodyTitle?.title_uz,
                  nameRu:bodyTitle?.title_ru,
                  nameEn:bodyTitle?.title_en
                })
              }}
            </p>
<!--            <p class="white&#45;&#45;text text-h6 font-weight-bold mb-2 px-4" v-else>-->
<!--              Ma'lumot topilmadi!-->
<!--            </p>-->

          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="Object.keys(pages).length > 0" fluid class="secondary_bg">
      <v-container class="mx-auto px-sm-5 px-0">
        <v-row class="responsive__style">
          <v-col cols="12" class="pt-10 mb-5 pl-4">
            <p class="text-h5 font-weight-bold text-start mb-1">
              {{ $t('navbar.structure') }}
            </p>
            <p class="iconColor" style="width: 60px; padding: 1px;"></p>
          </v-col>

          <v-col cols="12">
            <v-row class="d-flex align-start">
              <v-col cols="12" md="4" class="order-md-1 order-2 pr-4 pl-md-6 pl-4 mb-5" style="position: sticky; top:125px">
                <MenuTree />
                <v-card class="mt-8">
                  <iframe id="preview" style="border:0px;height:500px; width: 100%;"
                    src="https://xn--r1a.website/s/gfu_rasmiy_kanali"></iframe>
                </v-card>
              </v-col>
              <v-col cols="12" md="8" class="order-md-2 order-1 mb-5 px-4">
                <v-row>
                  <v-col cols="12">
                    <div>
                      <div v-if="getFindImage">
                        <img style="width: 100%; border-radius: 4px" :src="domen_name+getFindImage" alt="">
                      </div>
                      <br>
                      <p class="text-center mb-5" style="font-weight: bold; font-size: 25px" v-if="pages.employees.length > 0">
                        {{
                          getName({
                            nameUz:bodyTitle?.title_uz,
                            nameRu:bodyTitle?.title_ru,
                            nameEn:bodyTitle?.title_en
                          })
                        }}
                        {{ $t('actions.employee') }}
                      </p>

                      <v-col cols="12" v-for="(item, index) in pagesEmployee.sort((a, b)=> a.level-b.level)" :key="index" class="px-0 mb-10">
                        <v-row class="d-md-flex align-start ">
                          <v-col cols="12" md="5">
                            <img class="circle__image" :src="domen_name+item.image" />
                          </v-col>
                          <v-col cols="12" md="7">
                            <div>
                              <p class="iconColor--text text-h5">
                                <span>{{ getName({
                                  nameUz: item.postion_uz,
                                  nameRu: item.postion_ru,
                                  nameEn: item.postion_en,
                                  }) }}</span></p>
                              <p class="d-flex align-center">
                                <lord-icon class="mr-3" src="https://cdn.lordicon.com/bhfjfgqz.json" trigger="hover"
                                  colors="primary:#3db166" style="width:20px;height:20px">
                                </lord-icon>
                                {{
                                  getName({
                                    nameUz: item.full_name_uz,
                                    nameRu: item.full_name_ru,
                                    nameEn: item.full_name_en,
                                    })
                                }}
                              </p>
                              <p class="d-flex align-center">
                                <lord-icon class="mr-3" src="https://cdn.lordicon.com/ssvybplt.json" trigger="hover"
                                  colors="primary:#3db166" style="width:20px;height:20px">
                                </lord-icon>
                                {{ item.phone }}
                              </p>
                              <p class="d-flex align-center">
                                <lord-icon class="mr-3" src="https://cdn.lordicon.com/diihvcfp.json" trigger="hover"
                                  colors="primary:#3db166" style="width:20px;height:20px">
                                </lord-icon>
                                {{ item.email }}
                              </p>
                              <p class="d-flex align-center">
                                <lord-icon class="mr-3" src="https://cdn.lordicon.com/gigfpovs.json" trigger="hover"
                                  colors="primary:#3db166" style="width:20px;height:20px">
                                </lord-icon>
                                {{ getName({
                                  nameUz: item.res_uz,
                                  nameRu: item.res_ru,
                                  nameEn: item.res_en,
                                  })
                                }}
                              </p>

                              <v-btn v-if="item.teacher == true" @click="details(item, item.id)" rounded outlined color="#3db166" dark class="mt-2">
                                <div style="display: flex; align-items: center; color:var(--main-color)">
                                  <lord-icon class="mr-3" src="https://cdn.lordicon.com/zmkotitn.json" trigger="hover"
                                    colors="primary:#3db166" style="width:20px;height:20px"></lord-icon>
                                  <p class="pr-2 mb-0 font-weight-bold" style="text-transform: capitalize; color:var(--main-color)">{{ $t('actions.readMore') }}</p>
                              </div>
                              </v-btn>

                              <!-- <label v-if="(item.teacher_status == false) &&(item.articles_uz || item.books_uz || item.presentations_uz || item.text_uz || item.event_uz)" for="cbToggleVisibility" class="toggle-label" role="button">
                                <b class="pr-2">{{ $t('actions.readMore') }}</b>
                                <img width="32" src="@/assets/image/down.gif" alt="">
                              </label> -->

                            </div>
                          </v-col>
                          <v-col cols="12">
                            <div class="content-container">
                              <input type="checkbox" id="cbToggleVisibility">
                              <div class="target">
                                <v-card class="px-4">
                                  <v-tabs
                                    v-model="tab"
                                    background-color="transparent"
                                    color="basil"
                                    grow
                                  >
                                    <v-tab key='k1' href='#k1'>{{ $t('actions.article') }}</v-tab>
                                    <v-tab key='k2' href='#k2'>{{ $t('actions.book') }}</v-tab>
                                    <v-tab key='k3' href='#k3'>{{ $t('actions.art') }}</v-tab>
                                    <v-tab key='k4' href='#k4'>{{ $t('actions.loyiha') }}</v-tab>
                                    <v-tab key='k5' href='#k5'>{{ $t('actions.tadbir') }}</v-tab>
                                    <v-tabs-slider color="var(--logo-color)"></v-tabs-slider>
                                  </v-tabs>
                                  <v-tabs-items v-model="tab" class="py-5">
                                    <v-tab-item key='k1' value='k1'>
                                      <span v-html="locales =='uz' ?  item.articles_uz : locales =='ru' ? item.articles_ru : item.articles_en"></span>
                                    </v-tab-item>
                                    <v-tab-item key='k2' value='k2'>
                                      <span v-html="locales =='uz' ? item.books_uz : locales =='ru' ? item.books_ru : item.books_en"></span>
                                    </v-tab-item>
                                    <v-tab-item key='k3' value='k3'>
                                      <span v-html="locales =='uz' ?item.presentations_uz : locales =='ru' ? item.presentations_ru : item.presentations_en"></span>
                                    </v-tab-item>
                                    <v-tab-item key='k4' value='k4'>
                                      <span>{{ locales =='uz' ? item.text_uz : locales =='ru' ? item.text_ru : item.text_en }}</span>
                                    </v-tab-item>
                                    <v-tab-item key='k5' value='k5'>
                                      <span v-html="locales =='uz' ? item.events_uz : locales =='ru' ? item.events_ru : item.events_en"></span>
                                    </v-tab-item>
                                  </v-tabs-items>
                                </v-card>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <br>
                      <br>
                      <p style="font-size: 20px; font-weight: 500">
                        <!-- <span v-if="!isCenter" v-html="idVm?.text_uz"></span>
                        <span v-else v-html="idVm?.title_uz"></span> -->

                        <span v-if="pages"  v-html="pageText"></span>
                      </p>

                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container v-else fluid class="secondary_bg">
      <v-row>
        <v-col cols="12" style="height: 50vh; display: flex; justify-content: center; align-items: center">
          <v-progress-circular
            :size="70"
            :width="7"
            color="#0f172a"
            indeterminate
    ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="mx-auto px-4" v-if="$route.params.name == 'rttm'">
      <v-row class="responsive__style mx-auto">
        <v-col cols="12" class="text-center text-h5 font-weight-bold py-3">Web Loyihalar</v-col>
        <v-col cols="12" md="6" v-for="(vm, index) in rttm" :key="index">
          <div class="windowHover mx-auto my-3" @click="goToWebSite(vm.project_url)">
            <div class="positionRelative">
              <v-img :src="vm.image_url" class="hoverImage rounded"></v-img>
              <div class="positionAbsolute white--text text-center px-3 pt-13"
              style="display: inline-block;">
                <p class="text-h4 font-weight-bold mt-2 mb-0" style="line-height: 1.5;">
                  {{
                    getName({
                      nameUz: vm.title_uz,
                      nameRu: vm.title_ru,
                      nameEn: vm.title_en,
                    })
                  }}
                </p>
                <br>
                <br>
                <p class="text-h5 font-weight-light mt-2 mb-0" style="line-height: 1.5;">
                  {{
                    getName({
                      nameUz: vm.info_uz,
                      nameRu: vm.info_ru,
                      nameEn: vm.info_en,
                    })
                  }}
                </p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <UsefulLink />
  </div>
</template>

<script>
import MenuTree from '../components/menuTree.vue';
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import 'swiper/css/swiper.css';
import UsefulLink from "@/components/usefulLink.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NewsDetail",
  components: {
    Swiper,
    SwiperSlide,
    MenuTree,
    UsefulLink
  },
  data() {
    return {
      locales:localStorage.getItem("locale") || 'uz',
      domen_name: 'https://lib.uzgeouniver.uz:82',
      paramsName:'',
      tab: 'k1',
      isKafedra :false,
      isFaculty : false,
      isCenter : false,
      vm:null,
      facultyVm:null,
      swiperOptionTop: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        speed: 500,
        autoplay: true,
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 4, // looped slides should be the same
        spaceBetween: 1,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true
      },
    }
  },
  created() {
    this.$route.params.name != 'teacher' ?  this.getPageBySlug(this.$route.params.name) : ''
    this.$route.params.name == 'rttm' ?  this.getRttm() : ''

    this.getMenus();
    this.getPagesImages();

    let checkingRoute = this.$route.params.name;
    if(checkingRoute == 'neft-gaz' || checkingRoute == 'economy-geology' ||
    checkingRoute == 'aniq-tabiiy' || checkingRoute == 'foydali-qazilma' ||
    checkingRoute == 'muhandislik' || checkingRoute == 'umumiy-geologiya' ||
    checkingRoute == 'ijtimoiy-fanlar' || checkingRoute == 'foydali-qazilma-konlari' ||
    checkingRoute == 'geologiya-qidiruv') {
      this.kafedraRouteControl();
      this.isKafedra = true
      this.isFaculty = false
      this.isCenter = false
    }
    else if(checkingRoute == 'foydali-qazilma-konlari-faculty' ||
    checkingRoute == 'neft-gaz-konlari-faculty' ||
    checkingRoute == 'rossiya-ozbekiston-qoshma'){
      this.facultyRouteControl();
      this.isFaculty = true
      this.isKafedra = false
      this.isCenter = false
    }
    else if( checkingRoute == 'axborot-resurt-markazi' || checkingRoute == 'buxgalteriya' ||
    checkingRoute == 'devonxona-arxiv' || checkingRoute == 'ichki-nazorat-monitoring' ||
    checkingRoute == 'iqtidorli-talabalar' || checkingRoute == 'ishlar-boshqarmasi' ||
    checkingRoute == 'magistratura-bolimi' || checkingRoute == 'marketing-talabalar' ||
    checkingRoute == 'matbuot-markazi' || checkingRoute == 'oquv-uslubiy-bolim' ||
    checkingRoute == 'rttm' || checkingRoute == 'reja-moliya-bolim' ||
    checkingRoute == 'sirtqi-bolimi' || checkingRoute == 'talim-sifatini-nazorat' ||
    checkingRoute == 'xalqaro-hamkorlik-bolimi' || checkingRoute == 'yoshlar-manaviyat-marifat'
    ) {
      this.centerRouteControl();
      this.isKafedra = false
      this.isFaculty = false
      this.isCenter = true
    }
  },
  computed: {
    ...mapGetters("menu", ["menu"]),
    ...mapGetters("pagesImage", ["pagesImages"]),

    ...mapGetters("allPage", ["pages"]),
    ...mapGetters("rttm", ["rttm"]),

    ...mapGetters("kafedra", ["kafedra"]),
    ...mapGetters("kafedraEmployee", ["kafedraEmployee"]),

    ...mapGetters("faculty", ["faculty"]),
    ...mapGetters("facultyEmployee", ["facultyEmployee"]),

    ...mapGetters("center", ["center"]),
    ...mapGetters("centerEmployee", ["centerEmployee"]),

    pagesEmployee(){
      return this.pages.employees.filter((vm)=> vm.status)
    },
    bodyTitle(){
     return this.menu.find((item) => item.id == this.pages.menu)
    },
    getFindImage(){
     let findId = this.menu.find((vm) => vm?.slug == this.$route.params.name)?.id
     let findImage = this.pagesImages.find((item)=> item?.menu == findId)?.menus_image
     return findImage
    },
     idVm(){
      if(this.isKafedra){
        return this.vm && this.kafedra.find(x => x.category_title == this.vm)
      }
      else if(this.isFaculty){
        return this.vm && this.faculty.find(x => x.category_title == this.vm)
      }
      else if(this.isCenter){
        return this.vm && this.center.find(x => x.id == this.vm)
      }
    },
    teacherData(){
      if(this.isKafedra){
        return this.vm && this.kafedraEmployee.filter(x => x.category_kafedralar == this.vm)
      }
      else if(this.isFaculty){
        return this.facultyVm && this.facultyEmployee.filter(x => x.category_faculty == this.facultyVm)
      }
      else if(this.isCenter){
        return this.facultyVm && this.centerEmployee.filter(x => x.category_sections == this.facultyVm)
      }

    },

    newsImageById(){
      let datas = this.newsImages.filter((x)=> x.new == this.$route.query.id)
      return datas
    },
    pageText(){
    return this.locales == 'uz' ? this.pages?.text_uz : this.locales == 'ru' ? this.pages?.text_ru : this.pages?.text_en
  }
  },

  methods: {
    ...mapActions("menu", ["getMenus"]),
    ...mapActions("pagesImage", ["getPagesImages"]),

    ...mapActions("allPage",['getPageBySlug']),
    ...mapActions("rttm",['getRttm']),

    ...mapActions("kafedra",['getKafedra']),
    ...mapActions("kafedraEmployee", ['getKafedraEmployee']),

    ...mapActions("faculty",['getFaculty']),
    ...mapActions("facultyEmployee", ['getFacultyEmployee']),

    ...mapActions("center",['getCenter']),
    ...mapActions("centerEmployee", ['getCenterEmployee']),

    details(vm,id){
      const routeActivityInfo = this.$router.resolve({
        name: 'TeacherInfo',
        query: {
          section: this.$route.params.name,
          id: id,
        }
      });
      window.open(routeActivityInfo.href, '_blank');
    },

    kafedraRouteControl(){
    this.paramsName = this.$route.params.name
    this.paramsName == 'neft-gaz' ? this.vm = 1 : this.paramsName == 'economy-geology' ?
    this.vm = 3 : this.paramsName == 'aniq-tabiiy' ? this.vm = 4 : this.paramsName == 'foydali-qazilma' ?
    this.vm = 5 : this.paramsName == 'muhandislik' ? this.vm = 6 : this.paramsName == 'umumiy-geologiya' ?
    this.vm = 7 : this.paramsName == 'ijtimoiy-fanlar' ? this.vm = 8 : this.paramsName == 'foydali-qazilma-konlari' ?
    this.vm = 9 : this.vm = 10
    },
    facultyRouteControl(){
    this.paramsName = this.$route.params.name
    this.paramsName == 'foydali-qazilma-konlari-faculty' ? (this.vm = 1, this.facultyVm = 4) : this.paramsName == 'neft-gaz-konlari-faculty' ? (this.vm = 3, this.facultyVm = 5) : (this.vm = 4,  this.facultyVm = null)
    },
    centerRouteControl(){
    this.paramsName = this.$route.params.name
    this.paramsName == 'axborot-resurt-markazi' ? (this.vm = 3, this.facultyVm = 2) :
     this.paramsName == 'buxgalteriya' ? (this.vm = 8,this.facultyVm = 7) :
     this.paramsName == 'devonxona-arxiv' ? (this.vm = 10,this.facultyVm = 9) :
     this.paramsName == 'ichki-nazorat-monitoring' ? (this.vm = 6,this.facultyVm = 5) :
     this.paramsName == 'iqtidorli-talabalar' ? (this.vm = 13,this.facultyVm = 12) :
     this.paramsName == 'ishlar-boshqarmasi' ? (this.vm = 14,this.facultyVm = 13) :
     this.paramsName == 'magistratura-bolimi' ? (this.vm = 16,this.facultyVm = 15) :
     this.paramsName == 'marketing-talabalar' ? (this.vm = 15,this.facultyVm = 14) :
     this.paramsName == 'matbuot-markazi' ? (this.vm = 5,this.facultyVm = 4) :
     this.paramsName == 'oquv-uslubiy-bolim' ? (this.vm = 9,this.facultyVm = 8) :
     this.paramsName == 'rttm' ? (this.vm = 2,this.facultyVm = 1) :
     this.paramsName == 'reja-moliya-bolim' ? (this.vm = 7,this.facultyVm = 6) :
     this.paramsName == 'sirtqi-bolimi' ? (this.vm = 17,this.facultyVm = 16) :
     this.paramsName == 'talim-sifatini-nazorat' ? (this.vm = 12,this.facultyVm = 11) :
     this.paramsName == 'xalqaro-hamkorlik-bolimi' ? (this.vm = 4,this.facultyVm = 3) : (this.vm = 11,this.facultyVm = 10)
    },
    getDataById() {
      let dataId = this.$route.query.id
      this.getNewsById(dataId)
      this.getNewsImages()
    },
    goToWebSite(vm){
      window.open(vm, '_blank');
    }
  },

  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.$swiper
      const swiperThumbs = this.$refs.swiperThumbs.$swiper
      swiperTop.controller.control = swiperThumbs
      swiperThumbs.controller.control = swiperTop
    })
  },
}
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.windowHover {
  width: 100%;
  max-width: 550px;
  overflow: hidden;
  transition: all 0.3s ease;
  border-radius: 5px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .3);
  cursor: pointer;
}

.hoverImage {
  width: 100%;
  max-width: 550px;
  height: 315px;
  transition: all 0.5s ease;
}
.windowHover:hover .hoverImage {
  transform: scale(1.1);
}
.windowHover:hover .positionAbsolute {
  opacity: 1;
}

.positionRelative {
  position: relative;
  overflow: hidden;
}

.positionAbsolute {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.45);
}



::v-deep span p{
  background-color: transparent !important;
}
::v-deep span ul, span ol{
  background-color: transparent !important;
}
::v-deep span ol{
  background-color: transparent !important;
}

.gallery-top {
  height: 70% !important;
}

.gallery-thumbs {
  height: 20% !important;
}

.gallery-thumbs .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.9;
}

.gallery-thumbs .swiper-slide-active {
  opacity: 1;
}

.swiper-container {
  width: 100%;
  max-width: 900px;
  height: 300px;
}

.slide__image {
  width: 100%;
  max-width: 900px;
  height: auto;
}

.swiper-slide {
  text-align: center;
  position: relative;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.back__image {
  width: 100%;
  height: 400px;
  background-image: linear-gradient(to left, rgba(245, 246, 252, 0.01), #1e202f83, #1e202fcf, var(--main-color)), url('../assets/image/gfu8.jpg');
  background-position: center;
  background-size: cover;
  padding: 20px;
}

.position_rel {
  width: 100%;
  height: 60vh;
  position: relative;
  margin: 0 auto;
}

.title__style {
  position: absolute;
  left: 0%;
  top: 45%;
  transform: translate(0%, -45%);
}
.circle__image {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  margin: 0 10px 10px 10px;
  object-fit: cover;
  object-position: center;
  border: 1px solid #bbbec4;
  padding: 10px;
}

.toggle-label{
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
}
#cbToggleVisibility{
  position: absolute;
  left: -999999999px;
}

.target{
  display: none;
}

#cbToggleVisibility:checked ~ .target{
  display: block;
}

</style>
