<template>
  <div>
    <v-container class="mx-auto px-0">
      <v-row class="responsive__style">
        <v-col cols="12" class="pt-md-15 pt-10 mb-5">
          <p class="text-h6 font-weight-bold text-center" style="text-transform: uppercase;">{{ $t('faq.title') }}</p>
          <p class="iconColor" style="width: 60px; background-color: var(--main-color); margin: 0 auto; padding: 1px;"></p>
        </v-col>
        <v-col cols="12" md="8" class="pa-md-4 pa-2">
          <div>
            <v-expansion-panels focusable accordion class="px-2" v-model="panel">
              <v-expansion-panel v-for="(vm, index) in faq.slice(0, 5)" :key="index">
                <v-expansion-panel-header class="pl-3 py-3 pr-4 text-body-1 font-weight-bold">
                  {{ 
                    getName({
                      nameUz: vm.question_uz,
                      nameRu: vm.question_ru,
                      nameEn: vm.question_en,
                    })
                  }}
                  <template v-slot:actions>
                    <v-icon color="var(--main-color)">
                      $expand
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-3">
                  {{ 
                    getName({
                      nameUz: vm.answer_uz,
                      nameRu: vm.answer_ru,
                      nameEn: vm.answer_en,
                    })
                  }}
                </v-expansion-panel-content>
              </v-expansion-panel>

            </v-expansion-panels>
          </div>
        </v-col>
        <v-col cols="12" md="4" class="py-md-4 d-md-block d-none pa-2 vector__image">
          <div class="d-md-block d-none">
            <v-img class="ques__image-large" src="@/assets/image/gfu1.jpg"></v-img>
          </div>
          <div class="d-md-none d-block">
            <v-img class="ques__image-small" src="@/assets/image/gfu1.jpg"></v-img>
          </div>
          <div class="vectorLeftSecond d-md-block d-none">
            <v-img height="200" src="@/assets/image/vector.png"></v-img>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
    
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'Question',
  data() {
    return {
      panel: 0,
      quiz:[
      {
        ques:"faq.quiz1",
        answer:"faq.answ1"
      },
      {
        ques:"faq.quiz2",
        answer:"faq.answ2"
      },
      {
        ques:"faq.quiz3",
        answer:"faq.answ3"
      },
      {
        ques:"faq.quiz4",
        answer:"faq.answ4"
      }

    ]
    }
  },
  created(){
    this.getFaq()
  },
  computed:{
    ...mapGetters("faq", ["faq"]),
  },
  methods:{
    ...mapActions("faq", ["getFaq"]),
  }
}
</script>
    
<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
}

.ques__image-large {
  width: 100%;
  max-width: 370px;
  height: 370px;
  border-radius: 5px;
}

.ques__image-small {
  width: 100%;
  max-width: 800px;
  height: 400px;
  border-radius: 5px;
}

.vector__image {
  position: relative;
  z-index: 1;
}

.vectorLeftSecond {
  position: absolute;
  right: -60px;
  top: -40px;
  z-index: -99;
}
</style>