<template>
  <div>
    <v-card>
      <v-list>
        <v-list-group sub-group no-action v-for="(item, index) in buildHierarchy" :key="index">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-if="item.children.length > 0">
                <span>
                  {{
                    getName({
                      nameUz: item.title_uz,
                      nameRu: item.title_ru,
                      nameEn: item.title_en,
                    })
                  }}
                </span>
              </v-list-item-title>
              <v-list-item-title v-else>
                <router-link :to="item.slug" style="color:black">
                  {{
                    getName({
                      nameUz: item.title_uz,
                      nameRu: item.title_ru,
                      nameEn: item.title_en,
                    })
                  }}
                </router-link>
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="(vm, i) in ((item.children.length > 0) ? item.children : [])" :key="i" @click="test(vm)">
            <v-list-item-title>
              <router-link :to="vm.slug">
                {{
                  getName({
                    nameUz: vm.title_uz,
                    nameRu: vm.title_ru,
                    nameEn: vm.title_en,
                  })
                }}
              </router-link>
            </v-list-item-title>

          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card>
    <v-card class="mt-8">
      <iframe id="preview" style="border:0px;height:500px; width: 100%;"
        src="https://xn--r1a.website/s/gfu_rasmiy_kanali"></iframe>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: "Sidebar",
  data() {
    return {
      items: [
        {
          name: "Biz haqimizda",
          children: [
            { name: "Universitet haqida ma'lumot" },
            { name: "Meyoriy hujjatlar" },
            { name: "Savollar" },
          ]
        },
        {
          name: "Tuzilma",
          children: [
            { name: "Tashkiliy tuzilma" },
            { name: "Rektorat" },
            { name: "Markazlar va bo'limlar" },
            { name: "Fakultetlar" },
            { name: "Kafedralar" },
            { name: "Ilmiy tadqiqot institutlari" },
            { name: "Geoinnovatsion texnologiyalar markazi" },
          ]
        },
        {
          name: "Faoliyat",
          children: ["Faoliyat"]
        },
        {
          name: "Talabalar",
          children: ["Talabalar"]
        },
        {
          name: "Abituriyentlarga",
          children: ["Abituriyentlarga"]
        },
        {
          name: "Yangiliklar",
          children: ["Yangiliklar"]
        }
        ,
        {
          name: "Elektron Kutubxona",
        }
      ]
    }
  },
  created() {
    this.getMenus();
  },
  computed: {
    ...mapGetters("menu", ["menu"]),

    buildHierarchy() {
      const menuMap = new Map();
      this.menu.forEach(menu => {
        menu.children = [];
        menuMap.set(menu.id, menu);
      });
      this.menu.forEach(menu => {
        if (menu.parent !== null) {
          const parentMenu = menuMap.get(menu.parent);
          if (parentMenu) {
            parentMenu.children.push(menu);
          }
        }
      });
      const vm = this.menu.filter(menu => menu.parent === null);
      const topLevelMenus = vm.sort((a, b) => a.position - b.position);
      return topLevelMenus;
    },

    positionAbsolute() {
      return this.positionData == 'absolute' ? true : false
    },
    positionFixed() {
      return this.positionData == 'fixed' ? true : false
    }
  },
  methods: {
    ...mapActions("menu", ["getMenus"]),
  }
}
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}
</style>