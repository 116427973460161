<template>
  <div>
    <v-container fluid class="back__image">
      <v-row class="responsive__style mx-auto">
        <v-col col="12" class="position_rel">
          <div class="title__style">
            <p class="white--text text-h4 font-weight-bold mb-2 px-4">
              {{
                getName({
                nameUz: rektorat.postion_uz,
                nameRu: rektorat.postion_ru,
                nameEn: rektorat.postion_en,
                })
              }}
            </p>
            <v-breadcrumbs :items="elements" dark large class="pl-4">
              <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }" :disabled="item.disabled">
                <v-breadcrumbs-item>
                  <router-link style="color: white; text-decoration: none;" :to="item.href">
                    {{ item.text }}
                  </router-link>
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="mx-auto px-sm-5 px-0">
      <v-row class="responsive__style align-start">
        <v-col cols="12" class="pt-md-15 pt-10 mb-5 px-4">
          <p class="text-h5 font-weight-bold text-start mb-1">{{ $t('actions.information') }}</p>
          <p style="width: 60px; background-color: var(--main-color); padding: 1px;"></p>
        </v-col>

        <v-col cols="12" md="4" class="px-2 mb-5" style="position: sticky; top:125px">
          <v-card>
            <div class="d-block">
              <div class="text-center pt-3">
                <img class="circle__image" width="90%" :src="domen_name+rektorat.image" />
              </div>
              <div class="px-5 pt-3 pb-5">
                <v-card-title class="text-center pb-3 pt-1" style="text-transform: uppercase; font-size: 18px; font-weight: bold;">
                 <div class="mx-auto">
                  {{
                    getName({
                    nameUz: rektorat?.full_name_uz ? ((rektorat?.full_name_uz.split(' ')).slice(0, 2)).join(' ') : '',
                    nameRu: rektorat?.full_name_ru ? ((rektorat?.full_name_ru.split(' ')).slice(0, 2)).join(' ') : '',
                    nameEn: rektorat?.full_name_en ? ((rektorat?.full_name_en.split(' ')).slice(0, 2)).join(' ') : '',
                    })
                  }}
                  <br>
                  {{
                    getName({
                    nameUz: rektorat?.full_name_uz ? ((rektorat?.full_name_uz.split(' ')).slice(2)).join(' ') : '',
                    nameRu: rektorat?.full_name_ru ? ((rektorat?.full_name_ru.split(' ')).slice(2)).join(' ') : '',
                    nameEn: rektorat?.full_name_en ? ((rektorat?.full_name_en.split(' ')).slice(2)).join(' ') : '',
                    })
                  }}
                 </div>
                </v-card-title>
                <v-card-subtitle class="text-body-1 pt-3 font-weight-medium">
                  <div class="text-center">
                    {{
                      getName({
                      nameUz: rektorat?.postion_uz ? rektorat.postion_uz : '',
                      nameRu: rektorat?.postion_ru ? rektorat.postion_ru : '',
                      nameEn: rektorat?.postion_en ? rektorat.postion_en : '',
                      })
                    }}
                  </div>
                </v-card-subtitle>
                <v-card-subtitle class="my-2 py-1 text-body-1"><v-icon color="var(--logo-color)">mdi-clock</v-icon>
                  {{
                    getName({
                    nameUz: rektorat?.res_uz ? rektorat?.res_uz : '',
                    nameRu: rektorat?.res_ru ? rektorat?.res_ru : '',
                    nameEn: rektorat?.res_en ? rektorat?.res_en : '',
                    })
                  }}
                </v-card-subtitle>
                <v-card-subtitle class="my-2 py-1 text-body-1"><v-icon color="var(--logo-color)">mdi-phone</v-icon>
                  {{ rektorat.phone }}
                </v-card-subtitle>
                <v-card-subtitle class="my-2 py-1 text-body-1"><v-icon color="var(--logo-color)">mdi-email</v-icon>
                  {{ rektorat.email }}
                </v-card-subtitle>
                  <div class="d-flex justify-start my-3">
                    <div class="mr-3"><a target="_blank" :href="rektorat.telegram">
                      <v-img width="40" src="../assets/image/telegram.png"></v-img></a></div>

                    <div class="mx-3"><a target="_blank" :href="rektorat.instagram">
                      <v-img width="40" src="../assets/image/instagram.png"></v-img></a></div>

                    <div class="mx-3"><a target="_blank" :href="rektorat.facebook">
                      <v-img width="40" src="../assets/image/facebook.png"></v-img></a></div>

                    <div class="ml-3"><a target="_blank" :href="rektorat.youtube">
                      <v-img width="40" src="../assets/image/linkedin.png"></v-img></a></div>
                  </div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="8" class="mb-5 px-2">
          <v-card class="px-4">
            <v-tabs
              v-model="tab"
              background-color="transparent"
              color="basil"
              grow
            >
              <v-tab key='k1' href='#k1' v-if="!hide">{{ $t('actions.task') }}</v-tab>
              <v-tab key='k2' href='#k2'>{{ $t('actions.bio') }}</v-tab>
              <v-tab key='k3' href='#k3'>{{ $t('actions.ilmiy') }}</v-tab>

              <v-tabs-slider color="var(--logo-color)"></v-tabs-slider>
            </v-tabs>
            <v-tabs-items v-model="tab" class="py-5 px-3">
              <v-tab-item key='k1' value='k1'>
                <div v-if="vazifalari" v-html="vazifalari"></div>
                <div v-else style="text-align:center;">Ma'lumot topilmadi</div>
              </v-tab-item>
              <v-tab-item key='k2' value='k2'>
                <div v-if="biografiya" v-html="biografiya"></div>
                <div v-else style="text-align:center;">Ma'lumot topilmadi</div>
              </v-tab-item>
              <v-tab-item key='k3' value='k3'>
                <div v-if="ilmiy_faoliyati" v-html="ilmiy_faoliyati"></div>
                <div v-else style="text-align:center;">Ma'lumot topilmadi</div>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import rektorat from '@/store/modules/rektorat';
import { mapActions, mapGetters } from 'vuex'
export default {
  name: "RektorDetail",
  data() {
    return {
      locales:localStorage.getItem("locale") || 'uz',
      domen_name: 'https://lib.uzgeouniver.uz:82',
      value: null,
      tab: 'k1',
      hide: false,
      items: [
        'Maqolalar', 'Kitoblar', 'Taqdimotlar', 'Loyihalar', 'Tadbirlar',
      ],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    }
  },
  created(){
    this.getRektoratById(this.$route.query.id)
  },
  computed:{
    ...mapGetters("rektorat",["rektorat"]),
    elements(){
      return  [
        {
          text: 'Home',
          disabled: false,
          href: 'home',
        },
        {
          text: 'Rektorat',
          disabled: false,
          href: '/rektorat',
        },
        {
          text: this.rektorat?.full_name,
          disabled: true,
          href: 'Isoqov Maqsad Uzoqovich',
        }
      ]
    },
    vazifalari(){
     return this.getName({ nameRu: this.rektorat.vazifalari_ru, nameEn: this.rektorat.vazifalari_en, nameUz: this.rektorat.vazifalari_uz })
    },
    biografiya(){
      return this.getName({ nameRu: this.rektorat.biografiya_ru, nameEn: this.rektorat.biografiya_en, nameUz: this.rektorat.biografiya_uz })
    },
    ilmiy_faoliyati(){
      return this.getName({ nameRu: this.rektorat.ilmiy_faoliyati_ru, nameEn: this.rektorat.ilmiy_faoliyati_en, nameUz: this.rektorat.ilmiy_faoliyati_uz })
    }
  },
  methods:{
    ...mapActions("rektorat",["getRektoratById"]),
  },

}
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.circle__image {
  width: 330px;
  height: 330px;
  border-radius: 50%;
  margin: 0 10px 0 10px;
  object-fit: cover;
  object-position: center;
  border: 1px solid #bbbec4;
  padding: 10px;
}
.back__image {
  width: 100%;
  height: 400px;
  background-image: linear-gradient(to left, rgba(245, 246, 252, 0.01), #1e202f83, #1e202fcf, var(--main-color)), url('../assets/image/gfu10.jpg');
  background-position: center;
  background-size: cover;
  padding: 0;
}

.position_rel {
  width: 100%;
  height: 60vh;
  position: relative;
  margin: 0 auto;
}

.title__style {
  position: absolute;
  left: 0%;
  top: 45%;
  transform: translate(0%, -45%);
}

.image__detail {
  width: 250px;
  height: 250px;
}
</style>
